/**
 * Created by andrewlamers on 1/13/17.
 */
function Form_Data() {
    this.baseUrl = '/forms/{formId}/data';
}

Form_Data.prototype.form = function(formId) {
    this.formId = formId;
    return this;
};

Form_Data.prototype.getBaseUrl = function() {
    return this.baseUrl.replace('{formId}', this.formId);
};

Form_Data.prototype.update = function(id, data) {
    return $.ajax(this.getBaseUrl()+'/'+id, {
        method:'POST',
        processData: false,
        contentType: false,
        dataType: 'json',
        data: data
    });
};

Form_Data.prototype.create = function(data) {
    return $.ajax(this.getBaseUrl(name), {
        method: 'POST',
        data: data,
        processData: false,
        contentType: false,
        dataType: 'json'
    });
};

Form_Data.prototype.get = function() {
    return $.ajax(this.getBaseUrl());
};

Form_Data.prototype.destroy = function(_id) {
    return $.ajax(this.getBaseUrl()+'/'+_id, {
        method: 'DELETE'
    });
};

var formData = new Form_Data();