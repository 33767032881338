function DateCellRenderer () {}

// gets called once before the renderer is used
DateCellRenderer.prototype.init = function(params) {
    var format = 'M/D/YY h:mma';

    if(params.format) {
        format = params.format;
    }

    this.eGui = moment.utc(params.value).local().format(format);
};

// gets called once when grid ready to insert the element
DateCellRenderer.prototype.getGui = function() {
    return this.eGui;
};