// function to act as a class
function DateCellEditor () {}

// gets called once before the renderer is used
DateCellEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('type', 'date');
    this.eInput.className += 'ag-cell-edit-input';
    this.eInput.value = params.value;
};

// gets called once when grid ready to insert the element
DateCellEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateCellEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateCellEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateCellEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateCellEditor.prototype.isPopup = function() {
    return false;
};