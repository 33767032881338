jQuery.extend(jQuery.expr[':'], {
    focusable: function (el, index, selector) {
        var elj = $(el);
        if(elj.attr("type") === "hidden" || elj.attr("type") === "date")
            return false;

        return $(el).is('button, :input');
    }
});
function confirmModal(title, message, callback, failCallback) {
    $("#confirmModal").modal({
        onShow: function() {
            var m = $(this);
            m.find('[data-name=title]').html(title);
            m.find('[data-name=message]').html(message);
        },
        onHide: function() {
            var m = $(this);
            m.find('[data-name=title]').html('');
            m.find('[data-name=message]').html('');
        },
        onApprove: function() {
            if(callback()) {
                return true;
            } else {
                return false;
            }
        },
        onDeny: failCallback
    }).modal("show");
}
$(document).on('keypress', 'input,select', function (e) {
    if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        // Get all focusable elements on the page
        var $canfocus = $(':focusable');
        var index = $canfocus.index(this) + 1;
        if (index >= $canfocus.length) index = 0;
        $canfocus.eq(index).focus();
    }
});
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$("div.ui.suggest.search").search({
    saveRemoteData: false,
    minCharacters: 1,
    selectFirstResult: true,
    cache: false,
    showNoResults: false,
    onSelect: function() {
        var el = $(this).find('input.prompt');
        var $canfocus = $(':focusable');
        var index = $canfocus.index(el[0]) + 1;
        if (index >= $canfocus.length) index = 0;
        $canfocus.eq(index).focus();
    },
    onResultsOpen: function() {
        var el = $(this);
        var parent = el.parent();
        var input = parent.find('input.prompt');
        if(!input.is(':focus')) {
          parent.search("hide results");
        }
    },
    apiSettings: {
        cache: false,
        url: 'https://v2inventory.davewilson.tech',
        beforeSend: function(settings) {
            console.log("search settings", settings);

            var field = $(this).find('input').attr("name");
            var form_id = $(this).find('input').attr("data-form-id");
            settings.url = "/forms/"+form_id+"/suggestions?field="+field+"&query={query}";
            return settings;
        }
    }
});

$(".ui.dropdown").dropdown({
    selectOnKeydown:false,
    forceSelection: false,
    onChange: function() {
        console.log("change");
        var el = $(this).find('input.search');
        var $canfocus = $(':focusable');
        var index = $canfocus.index(el[0]) + 1;
        if (index >= $canfocus.length) index = 0;
        $canfocus.eq(index).focus();
    }
});

$('[data-remote=true]').dropdown({
    saveRemoteData: false,
    selectOnKeydown:false,
    forceSelection: false,
    onChange: function() {
        console.log("change");
        var el = $(this).find('input.search');
        var $canfocus = $(':focusable');
        var index = $canfocus.index(el[0]) + 1;
        if (index >= $canfocus.length) index = 0;
        $canfocus.eq(index).focus();
    },
    apiSettings: {
        cache:false,
        url: 'https://v2inventory.davewilson.tech',
        beforeSend: function(settings) {
            var el  = $(this);
            var type = el.attr('data-remote-type');

            if(type === "varieties") {
                settings.url = 'https://v2inventory.davewilson.tech/v2/api/varieties?query={query}&noFilter=true';
                settings.onResponse = function(response) {
                    return {
                        status: 'success',
                        results: _.map(response.data, function(item) {
                            return {name: item.name, value: encodeURIComponent(JSON.stringify(item))};
                        })
                    }
                }
            }

            if(type === "rootStocks") {
                settings.url = 'https://v2inventory.davewilson.tech/v2/api/rootStocks?query={query}&noFilter=true';
                settings.onResponse = function(response) {
                    return {
                        status: 'success',
                        results: _.map(response.data, function(item) {
                            return {name: item.name, value: encodeURIComponent(JSON.stringify(item))};
                        })
                    }
                }
            }

            if(type === "blocks") {
                settings.url = 'https://v2inventory.davewilson.tech/v2/api/inventory/items?query={query}&dropdown=block&noFilter=true';
                settings.onResponse = function(response) {
                    response.results = _.map(response.results, function(item){
                        return {name: item.name, value: encodeURIComponent(JSON.stringify(item))};
                    });
                    return response;
                }
            }

            if(type === "branches") {
                settings.url = 'https://v2inventory.davewilson.tech/v2/api/inventory/items?query={query}&dropdown=branch&noFilter=true';
                settings.onResponse = function(response) {
                    response.results = _.map(response.results, function(item){
                        return {name: item.name, value: encodeURIComponent(JSON.stringify(item))};
                    });
                    return response;
                }
            }
            if(type === "types") {
                settings.url = 'https://v2inventory.davewilson.tech/v2/api/types?query={query}&noFilter=true';
                settings.onResponse = function(response) {
                    return {
                        status: 'success',
                        results: _.map(response.data, function(item) {
                            return {name: item.name, value: encodeURIComponent(JSON.stringify(item))};
                        })
                    }
                }
            }

            return settings;
        }
    }
});

$('.message .close')
    .on('click', function() {
        $(this)
            .closest('.message')
            .transition('fade')
        ;
    })
;
$('.ui.checkbox')
    .checkbox();

$('.ui.radio.checkbox')
    .checkbox();

$('.ui.modal').modal();

$('.ui.form')
    .form({
        fields: {
            name     : 'empty',
            email: ['empty', 'email'],
            password: ['empty'],
            confirm_password: 'match[password]',
            title: ['empty']
        },
        inline:true,
        on:'blur'
    })
;

$("input[focus]").focus();