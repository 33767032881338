function CellRenderer () {}

// gets called once before the renderer is used
CellRenderer.prototype.init = function(params) {
    // create the cell
    this.cell = $(params.eGridCell);
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = '<span class="my-value"></span>' +
        '<div class="icons">' +
            '<div class="loading ui active inline tiny loader"></div>' +
            '<i class="saved inverted green checkmark icon"></i>' +
            '<i class="error inverted red warning sign icon"></i>' +
        '</div>';

    // get references to the elements we want
    this.eValue = this.eGui.querySelectorAll('.my-value')[0];

    // set value into cell
    if(params.value)
        this.eValue.innerHTML = params.valueFormatted ? params.valueFormatted : params.value
    else
        this.eValue.innerHTML = '';
};

// gets called once when grid ready to insert the element
CellRenderer.prototype.getGui = function() {
    return this.eGui;
};

// gets called whenever the user gets the cell to refresh
CellRenderer.prototype.refresh = function(params) {

    this.cell.removeClass('saving saved error');

    if(params.data._saving) {
        this.cell.addClass('saving');
    }
    else if(params.data._saved) {
        this.cell.addClass('saved');
    }
    else if(params.data._error) {
        this.cell.addClass('error');
    }

    if(params.data._saved || params.data._error) {
        var that = this;
        setTimeout(function() {

            if(!params.data._saving)
                that.cell.removeClass('saved error');

        }, 3000);
    }

    // set value into cell again
    if(params.value)
        this.eValue.innerHTML = params.valueFormatted ? params.valueFormatted : params.value;
    else
        this.eValue.innerHTML = '';
};

// gets called when the cell is removed from the grid
CellRenderer.prototype.destroy = function() {

};