function DeleteCellRenderer () {}

// gets called once before the renderer is used
DeleteCellRenderer.prototype.init = function(params) {
    params.eGridCell.style = 'padding-top:1px';
    this.eGui = document.createElement('a');
    this.eGui.className = 'ui red button';
    this.eGui.innerHTML = 'Delete';
};

// gets called once when grid ready to insert the element
DeleteCellRenderer.prototype.getGui = function() {
    return this.eGui;
};

// gets called when the cell is removed from the grid
DeleteCellRenderer.prototype.destroy = function() {

};